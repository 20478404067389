<template>
  <div id="assignments">
    <confirm 
      v-model="confirm" 
      @cancel="confirm=null" 
      @confirm="removeAssignment(confirm)"
    />
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snackbar"
      >
        {{ snackmsg }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-container>
      <v-card class="mb-3">
        <v-card-title>{{ formTitle }}</v-card-title>
        <v-card-text class="pb-6">
          <v-form v-model="valid">
            <v-row>
              <v-col>
                <v-select
                  v-model="assignment.member"
                  label="Member"
                  :items="users"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="assignment.queue"
                  label="Queue"
                  :items="queues"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="assignment.approver"
                  label="Approver?"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-row justify-center">
              <v-btn
                :disabled="!valid"
                elevation="2"
                @click="save"
                class="mr-1"
              >
                Submit
              </v-btn>
              <v-btn
                elevation="2"
                @click="reset"
                class="ml-1"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>Current Assignments</v-card-title>
        <v-card-text>
          <div v-if="assignmentsList.length === 0">
            Nothing found
          </div>
          <div v-if="assignmentsList.length > 0">
            <v-data-table
              :items="assignments"
              :headers="headers"
              @click:row="rowClick"
              dense
            >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                @click="confirm=item"
              >
                mdi-delete
              </v-icon>
            </template>
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Confirm from '../components/Confirm.vue';

export default {
  name: "AssignmentManagement",
  components: { Confirm },
  computed: {
		...mapGetters({
			authUser: 'auth/user',
			assignmentsList: 'queue-assignments/list',
      usersGet: 'users/get',
			queuesGet: 'queues/get',
      usersList: 'users/list',
      queuesList: 'queues/list'
		}),
    assignments() {
      return this.assignmentsList
        .filter(a => a.active)
        .map(a => {
        let member = this.usersGet(a.member);
        let queue = this.queuesGet(a.queue);
        return {
          ...a,
          member_name: `${member.first_name} ${member.last_name}`,
          queue_name: `${queue.name}`,
          approver_display: a.approver ? 'YES' : ''
        }
      })
    },
    users() {
      return this.usersList.map(u => {
        return {
          value: u.id,
          text: `${u.first_name} ${u.last_name}`
        }
      })
    },
    queues() {
      return this.queuesList.map(q => {
        return {
          value: q.id,
          text: q.name
        }
      })
    },
    formTitle() {
      if (this.editId > 0) {
        return `Editing Assignment`
      }

      return 'Add New Assignment'
    }
  },
  data () {
		return {
      valid: false,
      editId: 0,
      confirm: null,
			expanded: [],
      snackbar: false,
      snackmsg: '',
      assignment: {
        member: null,
        queue: null,
        approver: false
      },
      noEmptyField: [
        v => !!v || "This field must be filled"
      ],
			headers: [
				{ text: 'Member', value: 'member_name' },
				{ text: 'Queue', value: 'queue_name' },
				{ text: 'Approver', value: 'approver_display' },
        { text: 'Actions', value: 'actions', sortable: false }
			]
		}
	},
  methods: {
    save() {
      let test = new this.$FeathersVuex.api.QueueAssignment({...this.assignment})
      test.save()
    },
    reset() {
      this.assignment = { member: null, queue: null, approver: false }
      this.editId = 0;
    },
    feedbackSnack(msg) {
      const vm = this;
      this.snackmsg = msg;
      this.snackbar = true;
      setTimeout(() => { vm.snackbar = false; }, 5000)
    },
    rowClick (evt) {
      // console.log("EVT", JSON.stringify(evt));
      this.editId = parseInt(evt.id);
      this.assignment.id = parseInt(evt.id);
      this.assignment.member = evt.member;
      this.assignment.queue = evt.queue;
      this.assignment.approver = evt.approver ? true : false;
    },
    removeAssignment(assignment) {
      let rqData = {
        id: assignment.id,
        member: assignment.member,
        queue: assignment.queue,
        beginning: assignment.beginning,
        ending: new Date(),
        approver: assignment.approver,
        active: false,
      }
      console.log(JSON.stringify(rqData));
      let request = new this.$FeathersVuex.api.QueueAssignment({...rqData});
      request.save()
        .then(fb => {
          console.log(JSON.stringify(fb));
          this.feedbackSnack('Queue assignment removed.');
        })
        .catch((err) => {
          this.feedbackSnack('There was an error removing this assignment.');
          console.error(err);
        })
      this.confirm = null;
    }
  }
}
</script>