<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Remove {{ value ? value.member_name : '' }} from {{ value ? value.queue_name : '' }} queue?
        </v-card-title>

        <v-card-text>
          They will no longer be able to view or interact with nominations in this queue.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="$emit('confirm')"
          >
            Yes
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="$emit('cancel')"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      show: false
    }
  },
  watch: {
    value: function(newVal) {
      this.show = newVal;
    }
  }
}
</script>
